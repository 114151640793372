<template>
  <div class="ai-video-container">
    <div class="video-section-content">
      <div class="video-wrapper">
        <div class="example" v-if="is_example">示例视频</div>
        <div
          class="video-name-box"
          id="videoCont"
          style="width: 100%; height: 100%"
        ></div>
        <!-- <video
          class="video-name-box"
          id="videoCont"
          style="width: 100%; height: 100%"
          @canplay="getVidDur()"
          @loadstart="handleReady()"
        >
          <source
            src="https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4"
            type="video/webm"
          />
        </video> -->
      </div>
      <div class="video-section-handle" v-cloak>
        <div class="video-section-silder">
          <div class="secto-time">
            {{ secTotime(nowTime) }}
          </div>
          <div class="video-slider" id="slider-scroll">
            <el-slider
              v-model="markValue"
              range
              :disabled="sliderDisabled"
              :format-tooltip="formatTooltip"
              :step="1"
              @change="sliderChange"
              @input="sliderInput"
              :max="originVideoTime"
            >
            </el-slider>
            <el-tooltip placement="top" ref="videoSliderDot">
              <div slot="content">{{ secTotime(sliderTooltipTime) }}</div>
              <div
                class="video-slider-dot"
                id="slider"
                :style="{ left: vLeft + 'px' }"
              >
                <div class="video-slider-dot-bg"></div>
              </div>
            </el-tooltip>
            <div
              class="videoThumbnail"
              id="videoThumbnail"
              v-show="is_display_thumbnail"
            >
              <canvas id="video-thumbnail-canvas"></canvas>
            </div>
          </div>
          <div class="secto-time">
            {{ secTotime(originVideoTime) }}
          </div>
        </div>
        <div class="video-section-control">
          <div class="video-speed">1x</div>
          <img
            v-if="sectionList.length > 0 && handleIndex > 0"
            src="https://image.zhihuishu.com/zhs/b2cm/base1/202207/17e5120bbe62449aa0db8e06fadb296b.png"
            @click="goPre"
            width="25"
            alt="后退"
          />
          <div v-else style="width: 25px"></div>
          <img
            v-if="!playing"
            class="video-play"
            src="https://image.zhihuishu.com/zhs/b2cm/base1/202207/6cd1597df85148b9804b9c12e566484f.png"
            width="49"
            @click="videoPlay"
            alt="播放"
          />
          <img
            v-if="playing"
            class="video-play"
            src="https://image.zhihuishu.com/zhs/b2cm/base1/202207/9d11d0857b814afaa9d72c49d77689cf.png"
            width="49"
            @click="pauseVideo"
            alt="暂停"
          />
          <img
            v-if="sectionList.length > 0 && sectionList.length > handleIndex"
            src="https://image.zhihuishu.com/zhs/b2cm/base1/202207/f2f83dd579a3494987aee08537f03e33.png"
            @click="goNext"
            width="25"
            alt="前进"
          />
          <div v-else style="width: 25px"></div>
        </div>
      </div>
    </div>
    <div class="video-section-list" v-cloak>
      <div class="video-section-list-header">
        <div class="video-section-list-title nowrap">视频切片列表</div>
        <div
          class="video-section-add nowrap"
          :class="{ 'add-disabled': !videoId }"
          @click="addNewSection"
        >
          + 视频切片
        </div>
      </div>
      <div class="video-section-list-content">
        <el-scrollbar ref="videoScrollbar" class="video-preview-scrollbar">
          <div class="list-wrapper">
            <div
              class="video-item origin-video"
              :class="{ active: handleIndex == 0 }"
              @click="handDefault"
            >
              <div class="video-item-content">
                <div
                  :class="{
                    'video-time': true,
                    'video-time-checked': video_is_checked[0],
                  }"
                >
                  {{ secTotime(originVideoTime) }}
                </div>
                <div class="video-desc">
                  原视频<span class="disable-desc">（不可编辑）</span>
                </div>
              </div>
            </div>
            <div
              class="video-item"
              :class="{ active: handleIndex == index + 1 }"
              v-for="(item, index) in sectionList"
              :key="index"
              @click="handleItem(item, index + 1)"
              @keyup.enter="debounce(handleConfirm(index), 3000)"
            >
              <div class="video-item-content">
                <div
                  :class="{
                    'video-time': true,
                    'video-time-checked': video_is_checked[index + 1],
                  }"
                >
                  {{ secTotime(item.startTime) }}-{{ secTotime(item.endTime) }}
                </div>
                <div class="video-desc">
                  <div
                    v-if="editFlag && handleIndex == index + 1"
                    class="edit-container"
                  >
                    <el-input
                      :value="editVideoName"
                      class="source-input"
                      placeholder="请输入主题名称"
                      :ref="`input${index}`"
                      size="samll"
                      maxlength="25"
                      @input="handleInput"
                    ></el-input>
                  </div>
                  <p v-else class="nowrap">
                    视频切片{{ index + 1 }}：{{ item.title }}
                  </p>
                </div>
              </div>
              <div class="video-item-handle">
                <div
                  class="handle-checked"
                  v-if="handleFlag && !editFlag && handleIndex == index + 1"
                >
                  <img
                    src="https://image.zhihuishu.com/zhs/b2cm/base1/202208/652bc6c3a290455698469643b558792d.png"
                    @click.stop="editItem(item, index)"
                    alt="编辑"
                  />
                  <img
                    src="https://image.zhihuishu.com/zhs/b2cm/base1/202208/48fd4d4796db45fbba52e999bfca2c46.png"
                    @click.stop="deleteItem(index)"
                    alt="删除"
                  />
                </div>
                <div
                  class="handle-edit"
                  v-if="handleFlag && editFlag && handleIndex == index + 1"
                >
                  <div class="btn-cancel" @click.stop="handleCancel(index)">
                    取消
                  </div>
                  <div
                    class="btn-confirm"
                    @click.stop="debounce(handleConfirm(index), 3000)"
                  >
                    完成
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="loading-mark" v-show="loading">
      <div class="loading-spinner">
        <img
          src="https://file.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202208/e1f19e6d407f448583b329bdf9998133.webp"
          alt=""
        />
        <p>正在更新视频切片列表，请稍后…</p>
      </div>
    </div>
    <!-- 新手引导 -->
    <div class="guide" v-if="is_first_guide">
      <!-- 第一步 -->
      <div class="guide-content-wrapper guide-first" v-cloak>
        <div class="img-wrapper">
          <img
            ref="firstGuideImg"
            src="https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202208/ea00ab60b9244277b5d485a3f1317811.png"
            alt=""
          />
        </div>
        <div class="guide-btn-list">
          <p class="guide-btn guide-btn-cancel" @click="handleGuideCancel">
            取消
          </p>
          <p class="guide-btn guide-btn-next" @click="handleGuideNext">
            下一步
          </p>
        </div>
      </div>
    </div>
    <div class="guide" v-if="is_second_guide" v-cloak>
      <!-- 第二步 -->
      <div class="guide-content-wrapper guide-second">
        <div class="img-wrapper">
          <img
            ref="secondGuideImg"
            src="https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202208/17411259d6614b4eb40e8d4c6ef2ed30.png"
            alt=""
          />
        </div>
        <div class="guide-btn-list">
          <p class="guide-btn guide-btn-cancel" @click="handleGuideCancel">
            取消
          </p>
          <p class="guide-btn guide-btn-next" @click="handleGuideNext">
            下一步
          </p>
        </div>
      </div>
    </div>
    <div class="guide" v-if="is_third_guide" v-cloak>
      <!-- 第三步 -->
      <div class="guide-content-wrapper guide-third">
        <div class="img-wrapper">
          <img
            ref="thirdGuideImg"
            src="https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202208/62f1d7a68c244298852719ddcca573d2.png"
            alt=""
          />
        </div>
        <div class="guide-btn-list">
          <p class="guide-btn guide-btn-next" @click="handleGuideComplete">
            我知道了
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MessageBox } from 'element-ui'
export default {
  name: 'aiVideo',
  created() {
    this.sourceVideoMark = this.getQueryString('source')
    this.special = !!Number(this.getQueryString('special'))
    this.$nextTick(() => {
      this.is_example = this.getQueryString('special') == 1 ? true : false
    })
    this.userId = this.getQueryString('userId') // 主站跳转用户id
    this.countToCut = this.getQueryString('countToCut') // 主站新手导引是否显示
    if (!this.sourceVideoMark) {
      this.$message.error('无效的视频ID')
      return false
    }
  },
  mounted() {
    if (Number(this.countToCut) > 1) {
      this.is_first_guide = false
    } else {
      this.is_first_guide = true
    }
    this.slideScroll()
    if (this.userId) {
      this.queryBaseInfo(this.sourceVideoMark) // 获取基础信息
    }
    window.onresize = () => {
      this.clientWidthResize()
    }
  },
  data() {
    return {
      videoId: 0,
      sectionList: [],
      special: false,
      sliceDisable: false,
      sourceVideoMark: '',
      userId: null, // 主站用户跳转
      vLeft: 0,
      bgImg: '',
      playing: false,
      markValue: [8, 14],
      cacheMarkValue: [], // 编辑前缓存当前时间
      editVideoName: '',
      editFlag: false,
      editIndex: 0,
      sliderDisabled: true,
      handleFlag: false,
      handleIndex: -1,
      handleStatus: 1, // 1开始切片 2 切片中 3 切片完成 4编辑切片
      handleDesc: '开始切片',
      originVideoTime: 0,
      nowTime: 0, // 当前播放进度
      sliderTooltipTime: 0, // 拖动dot对应时间
      progressWidth: 0,
      cWidth: ((window.innerHeight - 162) * 16) / 9,
      cHeight: window.innerHeight - 162,
      sHeight: window.innerHeight - 146,
      loading: false,
      timer: null,
      video_is_checked: [false], // 标记视频是否选中
      cacheEditSliceName: '', // 记录当前新增或编辑的切片名称，匹配更新后的切片列表
      // 新手引导
      countToCut: 0, // 主站跳转是否显示
      is_first_guide: null,
      is_second_guide: null,
      is_third_guide: null,
      is_example: false, // 是否是示例视频
      is_display_thumbnail: false, // 视频缩略图是否展示
      is_cut_slice: false, // 正在选择切片段落
      // videoDom: null
    }
  },
  computed: {
    videoStyle() {
      return `width:${this.cWidth}px; height:${this.cHeight}px;margin:0 auto;overflow: hidden;`
    },
  },
  watch: {
    handleStatus(nVal) {
      let params = {
        1: '开始切片',
        2: '切片中',
        3: '切片完成',
        4: '编辑切片',
      }
      this.handleDesc = params[nVal]
    },
    editFlag(nVal) {
      if (!nVal) {
        this.handleStatus = 1
      }
    },
    handleIndex(nVal) {
      let sliderList = document.getElementsByClassName(
        'el-slider__button-wrapper'
      )
      for (const key in sliderList) {
        if (Object.hasOwnProperty.call(sliderList, key)) {
          const element = sliderList[key]
          element.style.display = nVal ? 'block' : 'none'
        }
      }
      for (const key in this.video_is_checked) {
        this.video_is_checked[key] = key == nVal ? true : false
      }
    }
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    // 本地测试代码 start
    getVidDur() {
      console.log('canplay🐶:>>>>>>>', 'src/views/aiVideo/index.vue')
      setTimeout(() => {
        this.videoDom = document.getElementById('videoCont')
        this.progressWidth =
          document.getElementById('slider-scroll').clientWidth
        this.originVideoTime = this.videoDom.duration
        console.log(
          'originVideoTime',
          this.originVideoTime,
          2 / this.originVideoTime
        )
        this.nowTime = Math.floor(this.videoDom.currentTime)
        console.log('this.nowTime🐶:>>>>>>>', this.nowTime, 'src/views/aiVideo/index.vue')
      }, 200)
    },
    handleReady() {
      console.log('ready🐶:>>>>>>>', 'src/views/aiVideo/index.vue')
      this.queryPollStatus(this.sourceVideoMark, true)
    },
    // 本地测试代码 end
    // 获取视频列表信息
    async queryBaseInfo(mark) {
      console.log('courseId', mark)
      this.loading = true
      const res = await this.$http.videoClipList({
        originalVideoId: mark,
        userId: this.userId,
      })
      console.log('res🐶:>>>>>>>', res, 'src/views/aiVideo/index.vue')
      if (res.data.code == 200) {
        this.loading = false
        console.log(res, '基础数据')
        if (res.data.rt.length == 0 || !res.data.rt) {
          this.$message.error('无效的视频ID')
          this.sliceDisable = true
        } else {
          // 获取所有视频列表，自行拆分
          let originList = res.data.rt.filter((i) => i.videoType == 1)
          let result = originList[0]
          let sectionList = res.data.rt.filter((i) => i.videoType != 1)
          this.sectionList = sectionList
          // _this.bgImg = 'https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202208/d897baa7f50e44cd86a3471fbc3f324e.png';
          this.bgImg = result.imageUrl
          this.videoId = result.videoId
          this.playVideo(this.videoId);
          // 本地开发测试代码，待删除
          // this.handleIndex = this.sectionList.length > 0 ? 1 : 0
        }
      } else {
        this.videoId = null
        this.$message.error(res.message)
      }
    },
    playVideo(id, src) {
      let _this = this
      $('#videoCont').Ableplayer(
        {
          id: id,
          src: src,
          autostart: false,
          image: _this.bgImg,
          control: {
            nextBtn: false,
            definiBtn: true,
            fullBtn: true,
          },
        },
        {
          onReady: function () {
            //初始化完成
            console.log('onReady')
            _this.$emit('ready', true)
            _this.loading = false
            document.getElementsByClassName('controlsBar')[0].style.opacity = 0
            _this.gainVideoDuration()
            _this.queryPollStatus(_this.sourceVideoMark, true)
            _this.handleIndex = _this.sectionList.length > 0 ? 1 : 0
            _this.$nextTick(() => {
              _this.progressWidth =
                document.getElementById('slider-scroll').clientWidth
            })
          },
          onComplete: function () {
            //播放完成
            console.log('onComplete')
          },
          onPause: function () {
            //暂停
            console.log('onPause')
            // this.$emit("Pase");
            _this.playing = false
          },
          onPlay: function () {
            //播放
            console.log('onPlay')
            _this.playing = true
            _this.$emit('videoplay', true)
          },
          onExitFullScreen: function () {
            //退出全屏
            console.log('onExitFullScreen')
          },
          onTime: function (time) {
            //播放进度改变
            _this.nowTime = time
            _this.progressWidth =
              document.getElementById('slider-scroll').clientWidth
            if (!_this.is_cut_slice) {
              _this.vLeft = (time / _this.originVideoTime) * _this.progressWidth
              _this.sliderTooltipTime = time
            }
            // _this.offsetLeft = time / _this.originVideoTime * _this.progressWidth;
            // console.log('time', time, _this.vLeft);
          },
          playbackRate: function (rate) {
            console.log(rate) //播放速率
          },
          playerNext: function () {
            console.log('next') //下一节
          },
        }
      )
    },
    gainVideoDuration() {
      let duration = ablePlayerX('videoCont').getDuration()
      console.log(duration, '原视频时长')
      this.originVideoTime = duration
    },
    //切换播放
    changeVideoPlay(videoId) {
      console.log('切换')
      this.vId = videoId
      this.clearVideo()
      this.initVideo(this.vId, this.src)
    },
    goClose() {
      if (
        navigator.userAgent.indexOf('Firefox') != -1 ||
        navigator.userAgent.indexOf('Chrome') != -1
      ) {
        window.location.href = 'about:blank'
        window.close()
      } else {
        window.opener = null
        window.open('', '_self')
        window.close()
      }
    },
    // 获取参数
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return decodeURI(r[2])
      return null
    },
    formatTooltip(val) {
      // val = this.milTimeHourFormat((val * this.docItem.videoTime)/100);
      val = this.secTotime(val)
      return val
    },
    secTotime(s) {
      var t = ''
      if (s > -1) {
        var min = Math.floor(s / 60)
        var sec = s % 60
        if (min < 10) {
          t += '0'
        }
        t += min + ':'
        if (sec < 10) {
          t += '0'
        }
        // t += sec.toFixed(0)
        t += Math.floor(sec)
      }
      return t
    },
    // 滑块更新
    sliderChange(value) {
      if (this.playing) {
        this.videoPlay()
      }
      if (this.sectionList[this.handleIndex - 1]) {
        this.sectionList[this.handleIndex - 1].startTime = value[0]
        this.sectionList[this.handleIndex - 1].endTime = value[1]
      } else {
        this.handDefault()
      }
      console.log(value, this.sectionList, '滑块更新11')
      this.is_cut_slice = this.sliderDisabled ? false : true
      this.is_display_thumbnail = false
      // 强制刷新
      this.$forceUpdate()
    },
    // 滑块拖动 显示缩略图
    sliderInput(value) {
      this.is_cut_slice = this.sliderDisabled ? false : true
      let left = 0
      if (this.sectionList[this.handleIndex - 1]) {
        if (value[0] !== this.sectionList[this.handleIndex - 1].startTime) {
          left =
            Math.round((value[0] / this.originVideoTime) * 10000) / 100.0 + '%'
          this.seekPlay(value[0])
          this.is_display_thumbnail = true
          this.creatCanvas(left)
        } else if (
          value[1] !== this.sectionList[this.handleIndex - 1].endTime
        ) {
          left =
            Math.round((value[1] / this.originVideoTime) * 10000) / 100.0 + '%'
          this.seekPlay(value[1])
          this.is_display_thumbnail = true
          this.creatCanvas(left)
        }
      }
    },
    videoPlay() {
      this.seekPlay(this.sliderTooltipTime)
      ablePlayerX('videoCont').play()
      // 本地开发测试代码
      // document.getElementById('videoCont').play()
      this.playing = true
    },
    // 暂停播放
    pauseVideo() {
      ablePlayerX('videoCont').pause();
      // 本地开发测试代码
      // document.getElementById('videoCont').pause()
      this.playing = false
    },
    // 跳转播放
    seekPlay(second) {
      ablePlayerX('videoCont').seek(second)
    },
    addNewSection() {
      console.log('新增切片')
      if (!this.videoId) {
        this.$message.warning('无效的视频ID')
        return
      }
      if (this.editFlag) {
        // 有未保存的内容
        this.$message.warning('请先保存还未保存的切片信息')
        return
      }
      let at = Math.floor(this.originVideoTime / 3)
      let et = Math.floor((this.originVideoTime / 3) * 2)
      let params = {
        startTime: at,
        endTime: et,
        title: '',
      }
      this.sectionList.unshift(params)
      this.editVideoName = ''
      this.handleStatus = 4
      this.handleFlag = true
      this.editFlag = true
      this.sliderDisabled = false
      this.markValue = [at, et]
      this.handleIndex = 1
      this.editIndex = 1
      this.$nextTick(() => {
        this.$refs[`input${this.handleIndex - 1}`][0].focus()
      })
    },
    handDefault() {
      if (this.editFlag) {
        this.handleCancel(this.handleIndex - 1)
      }
      this.handleFlag = false
      this.editFlag = false
      this.handleIndex = 0
      this.markValue = [0, 0]
      this.seekPlay(0)
    },
    slideScroll() {
      let _this = this
      let scroll = document.getElementById('slider-scroll')
      let slider = document.getElementById('slider')
      let elSlider = document.getElementsByClassName('el-slider')[0]
      // let progress = document.getElementById('progress')
      let sliderTooltipId =
        this.$refs.videoSliderDot.$el.attributes['aria-describedby'].nodeValue
      slider.onmousedown = function (event) {
        var event = event || window.event
        let leftVal = event.clientX - _this.vLeft
        // 模拟滑块的tooltip
        let sliderTooltip = document.getElementById(sliderTooltipId)
        _this.$nextTick(() => {
          sliderTooltip.style.left = _this.vLeft + leftVal - 26 + 'px'
        })
        // 拖动一定写到 down 里面才可以
        document.onmousemove = function (event) {
          var event = event || window.event
          _this.vLeft = event.clientX - leftVal
          if (_this.vLeft < 0) {
            _this.vLeft = 0
          } else if (_this.vLeft > slider.offsetLeft + scroll.offsetLeft) {
            _this.vLeft = slider.offsetLeft + scroll.offsetLeft
            _this.is_display_thumbnail = false
          } else if (_this.vLeft > elSlider.offsetWidth) {
            // 超过进度条最大宽度结束鼠标滑动事件
            _this.vLeft = elSlider.offsetWidth
            _this.is_display_thumbnail = false
            document.onmousemove = null
          }
          let timer = (_this.vLeft / scroll.clientWidth) * _this.originVideoTime // 需要跳转至的时间点
          sliderTooltip.style.left = _this.vLeft + leftVal - 26 + 'px'
          console.log('slideScroll:sdfsdfsdf', _this.vLeft, timer);
          _this.sliderTooltipTime = timer
          _this.seekPlay(timer)
          // 拖动滑块展示视频缩略图
          _this.is_display_thumbnail = true
          _this.creatCanvas(_this.vLeft + 'px')
          // console.log("已经播放了" + parseInt(_this.vLeft / (slider.offsetLeft + scroll.offsetLeft) * 100) + "%");
          window.getSelection
            ? window.getSelection().removeAllRanges()
            : document.selection.empty()
        }
      }
      document.onmouseup = function () {
        _this.is_display_thumbnail = false
        document.onmousemove = null //弹起鼠标不做任何操作
      }
    },
    goPre() {
      this.handleIndex -= 1
      if (this.handleIndex <= 0) {
        this.markValue = [0, 0]
        this.seekPlay(0)
      } else {
        let item = this.sectionList[this.handleIndex - 1]
        this.markValue = [item.startTime, item.endTime]
        this.seekPlay(item.startTime)
      }
    },
    goNext() {
      this.handleIndex += 1
      let item = this.sectionList[this.handleIndex - 1]
      this.markValue = [item.startTime, item.endTime]
      this.seekPlay(item.startTime)
    },
    clientWidthResize() {
      // 尺寸改变
      this.progressWidth = document.getElementById('slider-scroll').clientWidth
      this.vLeft = (this.nowTime / this.originVideoTime) * this.progressWidth
    },
    // 新增或编辑点击完成
    handleConfirm(index) {
      console.log('confirm🐶:>>>>>>>', this.editVideoName, this.sectionList[index], 'src/views/aiVideo/index.vue')
      this.pauseVideo()
      if (!this.editVideoName) {
        this.$message.warning('主题名称不能为空！')
        return
      }
      this.sectionList[index].title = this.editVideoName
      this.submitSlice(this.sectionList[index], index)
    },
    async submitSlice(sliceInfo, index) {
      console.log(
        'sliceInfo🐶:>>>>>>>',
        sliceInfo,
        index,
        'src/views/aiVideo/index.vue'
      )
      if (!sliceInfo.videoId) { // 新增视频切片
        let videoClipDto = { ...sliceInfo, videoSort: index + 1 }
        this.cacheEditSliceName = videoClipDto.title

        const res = await this.$http.addVideoClip({
          originalVideoId: this.sourceVideoMark,
          startTime: sliceInfo.startTime || sliceInfo.beginTime,
          endTime: sliceInfo.endTime,
          title: sliceInfo.title,
          userId: this.userId,
        })
        if (res.data.code === 200 && res.data.rt.resultStatus === 1) {
          this.loading = true
          this.cancel()
          this.handleStatus = 2
          this.querySliceStatus()
        } else {
          this.$message.error(res.data.rt.resultMessage)
        }
      } else if (sliceInfo.videoId) { // 编辑视频切片
        const editRes = await this.$http.updateVideoClip({
          originalVideoId: this.sourceVideoMark,
          userId: this.userId,
          clipVideoId: sliceInfo.videoId,
          startTime: sliceInfo.startTime,
          endTime: sliceInfo.endTime,
          title: sliceInfo.title,
        })
        if (editRes.data.code === 200 && editRes.data.rt.resultStatus === 1) {
          this.loading = true
          this.cancel()
          this.handleStatus = 2
          // this.$message.success('编辑成功')
          this.querySliceStatus()
        } else {
          this.$message.error(editRes.data.rt.resultMessage)
        }
      }
    },
    // 新增或删除视频切片后 更新列表，轮询，一直到状态为5为止
    async querySliceStatus() {
      const res = await this.$http.videoClipList({
        originalVideoId: this.sourceVideoMark,
        userId: this.userId,
      })
      if (res.data.code === 200) {
        let list = res.data.rt.filter((item) => item.videoType == 2)
        this.sectionList = list
        console.log('1234567🐶:>>>>>>>', this.cacheEditSliceName, this.editVideoName, list, 'src/views/aiVideo/index.vue')
        let result = list.filter(
          (item) => item.title == this.cacheEditSliceName
        )[0]
        console.log('lunxun result1🐶:>>>>>>>', result, 'src/views/aiVideo/index.vue')
        if (result?.status == 4) {
          this.loading = true
          setTimeout(() => {
            console.log('lunxun1🐶:>>>>>>>', 'src/views/aiVideo/index.vue')
            this.querySliceStatus()
          }, 1000)
        }
        if (result?.status == 5) {
          this.handleStatus = 3
          this.cancel()
          this.$message.success('切片成功')
          this.loading = false
          this.queryPollStatus(this.sourceVideoMark, true)
        }
        if (result?.status == 6) {
          this.handleStatus = 1
          this.cancel()
          this.loading = false
          this.$message.error('切片失败')
          // 清除切片
          this.queryPollStatus(this.sourceVideoMark)
        }
        if (!result) {
          this.queryPollStatus(this.sourceVideoMark, true)
        }
      } else {
        this.$message.error(res.data.msg)
      }
    },
    // 取消编辑或新增
    handleCancel(index) {
      if (!this.sectionList[index].title || !this.sectionList[index].videoId) {
        // 原视频title不存在，即删除
        this.sectionList.splice(index, 1)
      }
      this.editFlag = false
      this.markValue = [...this.cacheMarkValue]
      this.sliderChange(this.cacheMarkValue)
      // this.markValue = [0, 0];
      // 重置播放位置
      this.nowTime = this.markValue[0]
      this.progressWidth = document.getElementById('slider-scroll').clientWidth
      this.vLeft = (this.nowTime / this.originVideoTime) * this.progressWidth
      this.sliderTooltipTime = this.nowTime
      this.seekPlay(this.nowTime)
      this.sliderDisabled = true
      console.log('this.sectionList[index]', this.sectionList[index])
    },
    // 点击视频切片列表中某一项时
    handleItem(item, index) {
      console.log('handleItem🐶:>>>>>>>', item, index, this.editFlag, 'src/views/aiVideo/index.vue')
      if (this.editFlag) {
        // 有正在编辑的操作
        if (index == this.editIndex) {
          // 编辑的是自己
          return false
        } else {
          // this.handleConfirm(this.editIndex);
          return false
        }
      }
      this.handleFlag = true
      this.editFlag = false
      this.handleIndex = index
      this.markValue = [item.startTime, item.endTime]
      this.cacheMarkValue = [...this.markValue]
      console.log('markValue🐶:>>>>>>>', this.markValue, this.cacheMarkValue, 'src/views/aiVideo/index.vue')
      this.is_cut_slice = false
      this.seekPlay(item.startTime)
      this.sliderTooltipTime = item.startTime
    },
    handleInput(value) {
      value = value.trim()
      this.editVideoName = value
    },
    cancel() {
      this.editVideoName = ''
      this.editFlag = false
      this.handleFlag = false
      this.sliderDisabled = true
      this.editIndex = -1
    },
    editItem(item, index) {
      this.handleStatus = 4
      this.editVideoName = item.title
      this.editFlag = true
      this.sliderDisabled = false
      this.editIndex = index
      this.cacheEditSliceName = item.title
      this.$nextTick(() => {
        this.$refs[`input${index}`][0].focus()
      })
    },
    // 删除视频切片
    deleteItem(i) {
      console.log('this', this)
      MessageBox.confirm('确认要删除视频切片吗？删除后不可恢复', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteSlice(i)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    async deleteSlice(i) {
      let content = this.sectionList[i]
      const delRes = await this.$http.delVideoClip({
        clipVideoId: content.videoId,
      })
      if (delRes.data.code == 200 && delRes.data.rt.resultStatus == 1) {
        console.log(delRes.data, '删除切片结果')
        this.loading = false
        this.sectionList.splice(i, 1)
        this.$message({
          type: 'success',
          message: '删除成功!',
        })
        this.queryPollStatus(this.sourceVideoMark, true)
      } else {
        this.$message({
          type: 'error',
          message: delRes.data.rt.resultMessage
        })
        this.queryPollStatus(this.sourceVideoMark, true)
      }
    },
    // status 轮询查询or获取切片列表 status:false 获取切片列表 true 轮询
    async queryPollStatus(mark, status = false) {
      // status 轮询查询or获取切片列表 status:false 获取切片列表 true 轮询
      this.loading = true
      this.video_is_checked = [false]

      const res = await this.$http.videoClipList({
        originalVideoId: mark,
        userId: this.userId,
      })
      if (res.data.code == 200) {
        let result = res.data.rt || []
        // let resultStatus = []
        this.sectionList = result.filter(item => item.videoType == 2)
        this.loading = false
        // 标记视频列表选中状态
        result.forEach((item, index) => {
          this.video_is_checked.push(false)
          // resultStatus.push(item.status)
        })
        if (status && result.length > 0) {
          let key = 1
          let cacheEditSlice = this.sectionList.filter((item, index) => {
            if (item.title === this.cacheEditSliceName) {
              key = index + 1
              return item.title === this.cacheEditSliceName
            }
          })
          console.log('1234🐶:>>>>>>>', cacheEditSlice, this.cacheEditSliceName, key, 'src/views/aiVideo/index.vue')
          if (cacheEditSlice[0]) {
            this.handleItem(cacheEditSlice[0], key)
          } else {
            this.handleItem(this.sectionList[0], 1)
          }
          result.map((item) => {
            if (item.status == 4) {
              // 存在切片中的切片
              this.loading = true
              console.log('lunxun2');
              setTimeout(() => {
                console.log('lunxun3');
                this.queryPollStatus(this.sourceVideoMark, true)
              }, 1000)
            }
          })          
        } else {
          this.handDefault()
        }
      } else {
        this.$message.error(res.data.msg)
      }
    },
    // 新手引导取消事件
    handleGuideCancel() {
      this.is_first_guide = false
      this.is_second_guide = false
      this.is_third_guide = false
    },
    // 新手引导第一页下一步事件
    handleGuideNext() {
      this.is_first_guide = false
      this.is_second_guide = !this.is_second_guide ? true : false
      this.is_third_guide = this.is_second_guide ? false : true
    },
    // 新手引导第二页完成事件
    handleGuideComplete() {
      this.is_first_guide = false
      this.is_second_guide = false
      this.is_third_guide = false
    },
    // 生成缩略图
    creatCanvas(left = '0px') {
      const videoThumbnailWrapper = document.getElementById('videoThumbnail')
      const video = document.getElementById('vjs_videoCont_html5_api')
      const canvas = document.getElementById('video-thumbnail-canvas')
      if (video) {
        const height = video.videoHeight
        const width = video.videoWidth
        const ctx = canvas.getContext('2d')
        canvas.width = width * 0.15
        canvas.height = height * 0.15
        videoThumbnailWrapper.style.top = -height * 0.15 + 'px' //  - 35
        videoThumbnailWrapper.style.left = left
        video.addEventListener('seeked', function (e) {
          // seeked：查找结束。当用户已经移动/跳跃到视频中新的位置时触发
          ctx.drawImage(video, 0, 0, width * 0.15, height * 0.15)
        })
      }
    },
    /*
     * 防抖
     * @param {Function} func
     * @param {number} delay
     */
    debounce(func, delay = 1000) {
      let timer = null
      return function () {
        if (timer === null) {
          timer = setTimeout(() => {
            func.apply(this, arguments)
            timer = null
          }, delay)
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
input,
textarea {
  /* box-shadow: none;
	-webkit-box-shadow:none; */
  outline: none;
  // -webkit-appearance: none; /*去除系统默认的样式*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* 点击高亮的颜色*/
}
input:focus {
  outline: none;
}
.ai-video-container {
  display: flex;
  height: 100vh;
}
/* 播放器部分 */
.video-section-content {
  /* flex: 1; */
  width: calc(100% - 320px - 12px);
  /* width: calc(100% - 20.21% - 12px); */
  height: 100%;
  /* display: flex;
	flex-direction: column;
	justify-content: space-between; */
}
.video-wrapper {
  position: relative;
  /* flex: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000c1d;
  /* height: calc(100% - 13.89%); */
  height: calc(100% - 150px);
}
/* 视频封面，暂时不做 */
.video-wrapper .video-cover {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100%;
  /* padding-top: 19.276%; */
  /* background-color: rgba(0, 0, 0, .3); */
  background-image: url('https://image.zhihuishu.com/zhs_yufa_150820/b2cm/base1/202208/d897baa7f50e44cd86a3471fbc3f324e.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  /* background-blend-mode: multiply; */
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.video-wrapper .video-cover::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5002;
}
.video-wrapper .video-cover .cover-title {
  margin-bottom: 3.5rem;
  font-size: 5rem; /* 80px */
  font-weight: 500;
  line-height: 80px;
}
.video-wrapper .video-cover .cover-record-time {
  margin-bottom: 2.1rem;
  font-size: 3.75rem; /* 60px */
  line-height: 60px;
}
.video-wrapper .video-cover .cover-Lecturer {
  font-size: 3.75rem; /* 60px */
  line-height: 60px;
}
.video-section-handle {
  flex: 1;
  /* margin-top: 12px; */
  /* height: 13.89%; */
  height: 150px;
  color: #999;
  font-size: 14px;
  background-color: #fff;
}
.video-section-silder {
  display: flex;
  align-items: center;
  padding: 12px 16px 0;
}
.secto-time {
  width: 45px;
}
.video-slider {
  flex: 1;
  margin: 0 20px;
  position: relative;
}
.video-slider-dot {
  position: absolute;
  border-radius: 2px;
  top: 11px;
  z-index: 2002;
  width: 4px;
  height: 16px;
  background-color: #3982ff;
  cursor: -webkit-grab;
  cursor: grab;
}
.video-slider-dot-bg {
  width: 20px;
  height: 20px;
  transform: translate(-30%, -5%);
}
.video-section-control {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  position: relative;
}
.video-section-control img {
  cursor: pointer;
}
.video-speed {
  position: absolute;
  left: 16px;
  width: 27px;
  height: 16px;
  /* padding: 2px 8px; */
  background-color: #9fa2b1;
  color: #fff;
  border-radius: 1px;
  cursor: pointer;
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
}
.video-play {
  margin: 0 118px;
}
/* 切片列表部分 */
.video-section-list {
  /* flex: 1; */
  /* width: 20.21%; */
  /* height: 100%; */
  height: 100vh;
  /* max-width: 388px; */
  width: 320px;
  margin-left: 12px;
  /* margin-top: 16px; */
  background-color: #fff;
}
.video-section-list-header {
  box-sizing: border-box;
  position: relative;
  /* height: 5.74%; */
  height: 62px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 2px solid rgba(97, 110, 136, 0.2);
}
/* .video-section-list-content {
	height: calc(100% - 62px);
} */
.video-section-list-content .list-wrapper {
  max-height: calc(100vh - 62px);
}
.video-section-add {
  /* position: absolute;
	right: 16px;
	background-image: url(https://image.zhihuishu.com/zhs/b2cm/base1/202207/4f305d0cbd9a47dfbcd9ec90534ea08e.png);
	background-repeat: no-repeat;
	background-size: contain;
	width: 16px;
	height: 16px; */
  color: #1672ff;
  font-weight: 600;
  border-radius: 2px;
  border: 2px solid #1672ff;
  padding: 4px 15px;
  cursor: pointer;
  font-size: 14px;
}
.handle-slice {
  width: 80%;
  margin: auto;
  height: 38px;
  line-height: 38px;
  background: #3982ff;
  color: #fff;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  margin-top: 14px;
}
.slice-disabled,
.add-disabled {
  color: #c0c4cc;
  cursor: not-allowed;
  background-image: none;
  opacity: 0.5;
  /* background-color: #fff;
	border-color: #ebeef5; */
}
.video-section-list-title {
  font-size: 18px;
  font-weight: 500;
}
.video-item {
  padding: 4px 16px;
  cursor: pointer;
  position: relative;
  display: flex;
  border-radius: 2px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}
.video-item:hover {
  background-color: #f5f7f9;
}
/* .video-item:hover .video-item-handle {
	display: block;
} */
.active {
  background-color: #e6eefa;
}
.active:hover {
  background-color: #e6eefa;
}
.video-item-content {
  width: 60%;
}
/* background: #3982FF;
border-radius: 2px; */
.video-time {
  color: #9fa2b1;
  font-size: 12px;
  display: flex;
}
/* 切片选中状态样式 */
.video-time-checked {
  color: #1672ff;
}
.video-desc {
  font-size: 14px;
  margin-top: 4px;
}
.disable-desc {
  color: #ff8316;
}
.edit-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.edit-container input {
  background: #e6eefa;
  border: none;
  padding-left: 0;
}
.edit-container .icon {
  font-size: 24px;
  font-weight: 600;
  margin-left: 20px;
  cursor: pointer;
}
.video-item-handle {
  width: 40%;
  /* display: none; */
  /* flex: 1; */
  text-align: right;
  /* margin: auto; */
  /* position: absolute; */
  /* right: 16px; */
}
.video-item-handle img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.btn-cancel {
  color: #3982ff;
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid #3982ff;
  padding: 4px 8px;
  display: inline-block;
}
.btn-confirm {
  display: inline-block;
  font-size: 14px;
  color: #fff;
  background: #3982ff;
  border-radius: 2px;
  padding: 4px 8px;
  margin-left: 4px;
}
.i-edit {
  margin-right: 10px;
}
.loading-circle {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
}
.pd0 {
  padding-top: 0;
}
@-webkit-keyframes slideInUp {
  from {
    height: 0;
  }

  to {
    height: 9rem;
  }
}
@keyframes slideInUp {
  from {
    height: 0;
  }

  to {
    height: 9rem;
  }
}
.loading-mark {
  position: absolute;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.6);
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* -webkit-transition: opacity .3s;
	transition: opacity .3s; */
}
.loading-spinner {
  top: 50%;
  margin-top: -61px;
  width: 100%;
  text-align: center;
  position: absolute;
}
.loading-spinner p {
  margin-top: 16px;
  font-size: 20px;
  color: #fff;
}
.loading-spinner img {
  width: 60px;
  height: 60px;
}
.ivu-btn-primary.active,
.ivu-btn-primary:active {
  background: #ffc000;
}
.ivu-btn-primary {
  background: #ffc000;
  border-color: #ffc000;
}
.ivu-btn-primary:hover {
  background: #ffc000;
  border-color: #ffc000;
}
.el-slider__button {
  width: 4px;
  height: 4px;
  border: 6px solid #409eff;
}
.el-input__inner {
  height: 22px;
  font-size: 14px;
}
.el-slider__runway.disabled .el-slider__button-wrapper:hover {
  cursor: default;
}
.el-message-box .el-message-box__btns button:nth-child(2) {
  background-color: #1672ff;
  border-color: #1672ff;
}
.el-slider__runway.disabled .el-slider__button.hover {
  cursor: default;
}
/* 强制不换行 */
.nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* 输入框placeholder字体样式 */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-size: 14px;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  font-size: 14px;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  font-size: 14px;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-size: 14px;
}

/* 新手引导 */
[v-cloak] {
  display: none;
}
.guide {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.guide img {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
}
.guide-first .img-wrapper {
  position: absolute;
  top: 12px;
  right: 9px;
  width: 373px;
  height: 312px;
}
.guide-first .guide-btn-list {
  position: absolute;
  top: 232px;
  right: 77px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 244px; */
  /* margin-top: 11%; */
  /* 36px */
  /* padding: 0 13.5%;  */
  /* 0 45px */
}
.guide-btn {
  box-sizing: border-box;
  /* width: 44.5%; */
  /* height: 19.387%; */
  /* max-width: 106px; */
  /* max-height: 38px; */
  width: 106px;
  height: 38px;
  border-radius: 2px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 2.71;
  text-align: center;
  border: 1px solid #3982ff;
}
.guide-btn:hover {
  cursor: pointer;
}
.guide-btn-cancel {
  color: #3982ff;
  margin-right: 32px;
}
.guide-btn-next {
  background: #3982ff;
  color: #ffffff;
}
.guide-second .img-wrapper {
  position: absolute;
  left: 8%;
  bottom: 67px;
  width: 739px;
  height: 376px;
}
.guide-second .guide-btn-list {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: calc(8% + 247px);
  bottom: 282px;
}
.guide-third .img-wrapper {
  position: absolute;
  top: 90px;
  right: 0;
  width: 401px;
  height: 344px;
}
.guide-third .guide-btn-list {
  position: absolute;
  top: 364px;
  right: 147px;
}
/* 示例视频角标 */
.example {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 96px;
  height: 40px;
  background: #1672ff;
  border-radius: 0px 0px 4px 0px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
}
/* 视频缩略图 */
.videoThumbnail {
  position: absolute;
  z-index: 2002;
  transform: translateX(-50%);
}

.source-input {
  outline: none;
  ::v-deep .el-input__inner {
    padding: 0;
    border: none;
    background: transparent;
  }
}
</style>
